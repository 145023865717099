import React from 'react';
import path from "./path";
import DefaultLayout from "../layouts/DefaultLayout";
import AuthLayout from "../layouts/AuthLayout";
import OtherLayout from "../layouts/OtherLayout"
import siteConfig from "../config/site-config";

let DelayTime = siteConfig.lazy_suspense_delay;
let SiteName = `| ${siteConfig.company_name}`;
let SiteName2 = `${siteConfig.company_name_with_tagline}`;

const Login = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Login" */ "../pages/auth/login"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Signup = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Signup" */ "../pages/auth/Signup"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ForgotPassword = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Signup" */ "../pages/auth/forgotPassword"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const RecoveryPassword = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Signup" */ "../pages/auth/recoveryPassword"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Home = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/home/Home"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const AboutUs = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/aboutUs/AboutUs"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const TermsConditions = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/termsConditions/TermsConditions"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});


const PhysicalActivity = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/termsConditions/PhysicalActivity"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const BalancedNutrition = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/balanceNutrition/BalancedNutrition"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});
const SettingRealisticGoals = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/settingsGoal/SettingRealisticGoals"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});
const MealPlanning = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/mealPlanning/MealPlanning"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});
const DietGuidelinesT = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/diet/DietGuideLines"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const EquilibriumPoint = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/equilibriumPoint/EquilibriumPoint"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const DietGuidelines = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/dietGuidelines/DietGuidelines"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const PrivacyPolicy = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/privacyPolicy/PrivacyPolicy"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const WeightManagement = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/weightManagement/WeightManagement"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ScienceDeit = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/scienceDiet/ScienceDeit"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});
const Support = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/LandingPage/support/Support"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const MealPlan = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/MealPlan/MealPlan"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const AddMealItem = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/AddMealItem/AddMealItem"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const WeightEntry = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/WeightEntry/WeightEntry"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const MyProfile = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/MyProfile/MyProfile"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Analytics = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/Analytics/Analytics"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Subscribe = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/Subscribe/Subscribe"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const MyMembership = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/MyMembership/MyMembership"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const DeleteAccount = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/delete-account/DeleteAccount"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const AppDeveloperMerchantid = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Meal Plan" */ "../pages/AppDeveloperMerchantid/AppDeveloperMerchantid"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

export const private_routes = [
    { path: `${process.env.PUBLIC_URL}${path.meal_plan}`, Component: <MealPlan pageTitle={`Meal entry ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.add_meal_item}`, Component: <AddMealItem pageTitle={`Add meal item ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.weight_entry}`, Component: <WeightEntry pageTitle={`Weight entry ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.my_profile}`, Component: <MyProfile pageTitle={`My profile ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.analytics}`, Component: <Analytics pageTitle={`Analytics ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.subscribe}`, Component: <Subscribe pageTitle={`Subscribe ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.support}`, Component: <Support pageTitle={`Support ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.my_memberships}`, Component: <MyMembership pageTitle={`My Membership ${SiteName}`} />, Layout: <DefaultLayout /> },
]

export const public_routes = [
    { path: `${process.env.PUBLIC_URL}${path.registration}`, Component: <Signup pageTitle={`Sign Up ${SiteName}`} />, Layout: <AuthLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.login}`, Component: <Login pageTitle={`Login ${SiteName}`} />, Layout: <AuthLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.forgot_password}`, Component: <ForgotPassword pageTitle={`Forgot Password ${SiteName}`} />, Layout: <AuthLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.recovery_password}`, Component: <RecoveryPassword pageTitle={`Recovery Password ${SiteName}`} />, Layout: <AuthLayout /> },
]

// ************ Example for public private route *********** //
export const public_private_routes = [
    { path: `${process.env.PUBLIC_URL}${path.home}`, Component: <Home pageTitle={`${SiteName2}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.landingPage}`, Component: <Home pageTitle={`${SiteName2}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.about_us}`, Component: <AboutUs pageTitle={`About Us ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.balance_nutrition}`, Component: <BalancedNutrition pageTitle={`Balanced Nutrition ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.physical_activity}`, Component: <PhysicalActivity pageTitle={`Physical Activity ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.realistic_goals}`, Component: <SettingRealisticGoals pageTitle={`Setting Realistic Goals ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.meal_planning}`, Component: <MealPlanning pageTitle={`Setting Realistic Goals ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.equilibrium_point}`, Component: <EquilibriumPoint pageTitle={`Equilibrium Point ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.diet_guidelines}`, Component: <DietGuidelinesT pageTitle={`DietGuide lines ${SiteName}`} />, Layout: <DefaultLayout /> },

    { path: `${process.env.PUBLIC_URL}${path.privacy_policy}`, Component: <PrivacyPolicy pageTitle={`Privacy Policy ${SiteName}`} />, Layout: <DefaultLayout /> },

    { path: `${process.env.PUBLIC_URL}${path.science_diet}`, Component: <ScienceDeit pageTitle={`Science Of Diet ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.physical_activity_guide}`, Component: <DietGuidelines pageTitle={`Physical Activity Guide ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.weight_management}`, Component: <WeightManagement pageTitle={`Weight Management ${SiteName}`} />, Layout: <DefaultLayout /> },

    { path: `${process.env.PUBLIC_URL}${path.terms_conditions}`, Component: <TermsConditions pageTitle={`Terms & Conditions ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.app_developer_merchantid}`, Component: <AppDeveloperMerchantid pageTitle={`Terms & Conditions ${SiteName}`} />, Layout: <OtherLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.delete_account}`, Component: <DeleteAccount pageTitle={`Delete Account ${SiteName}`} />, Layout: <OtherLayout /> },
]
